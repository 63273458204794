import { StoreAction } from "../../@core/constants/action";

const initialState = {
  leadView: 0, // 0 -kanban, 1-table
  all_lead: [],
  today_lead: [],
  kanban_lead: [],
  finalSearch: false,
  leadHistoryList: [],
  leadFollowupList: [],
  missLeadFollowUpList: [],
  isLeadsLoading: false,
  search_kanban_lead: [],
  leadFollowupLoading: false,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.GET_ALL_LEAD_DATA: {
      return {
        ...state,
        all_lead: action.payload,
      };
    }
    case StoreAction.GET_ALL_KANBAN_LEAD: {
      return {
        ...state,
        kanban_lead: action.payload,
      };
    }
    case StoreAction.SET_LEAD_VIEW: {
      return {
        ...state,
        leadView: action.payload,
      };
    }
    case StoreAction.GET_FOLLOWUP_DATA:
      return {
        ...state,
        leadFollowupList: action.payload,
      };
    case StoreAction.GET_HISTORY_DATA:
      return {
        ...state,
        leadHistoryList: action.payload,
      };
    case StoreAction.SET_IS_LEAD_LOADING:
      return {
        ...state,
        isLeadsLoading: action.payload,
      };
    case StoreAction.SET_IS_LEAD_FOLLOWUP_LOADING:
      return {
        ...state,
        leadFollowupLoading: action.payload,
      };
    case StoreAction.GET_ALL_KANBAN_LEAD_SEARCH: {
      return {
        ...state,
        search_kanban_lead: action.payload,
      };
    }
    case StoreAction.SET_LEAD_SERACH: {
      return {
        ...state,
        finalSearch: action.payload,
      };
    }
    case StoreAction.SET_MISS_FOLLOWUP_LEAD_DATA: {
      return {
        ...state,
        missLeadFollowUpList: action.payload,
      };
    }
    default:
      return state;
  }
}
