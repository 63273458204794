import { StoreAction } from "../../@core/constants/action";

const initialState = {
  deviceType: 1, // 1 - Add device, 2 - Reconnect Device
  wapMonkeyUserId: 0,
  connectDeviceData: null,
  wapMonkeyWebSocket: null, // Wap Monkey WebSocket
  reconnectDeviceData: null,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case StoreAction.SET_WAP_MONKEY_WEB_SOCKET:
      return { ...state, wapMonkeyWebSocket: action.payload };
    case StoreAction.SET_WAP_MONKEY_USER_ID:
      return { ...state, wapMonkeyUserId: action.payload };
    case StoreAction.SET_WAP_MONKEY_RECONNECT_DEVICE_DATA:
      return { ...state, reconnectDeviceData: action.payload };
    case StoreAction.SET_WAP_MONKEY_DEVICE_TYPE:
      return { ...state, deviceType: action.payload };
    case StoreAction.SET_WAP_MONKEY_DEVICE_DATA:
      return { ...state, connectDeviceData: action.payload };
    default:
      return state;
  }
}
