// ** Reducers Imports
import layout from "../layout";
import userReducer from "./userReducer";
import leadReducer from "./leadReducer";
import unitReducer from "./unitReducer";
import noteReducer from "./noteReducer";
import taskReducer from "./taskReducer";
import todoReducer from "./todoReducer";
import leaveReducer from "./leaveReducer";
import commonReducer from "./commonReducer";
import holidayReducer from "./holidayReducer";
import templateReducer from "./templateReducer";
import departmentReducer from "./departmentReducer";
import notificationReducer from "./notificationReducer";
import attendanceReducer from "./attendanceReducer";
import automationRulesReducer from "./automationRulesReducer";
import chatReducer from "./chatReducer";
import serviceReducer from "./serviceReducer";
import wapMonkeyReducer from "./wapMonkeyReducer";

const rootReducer = {
  layout,
  userReducer,
  leadReducer,
  unitReducer,
  noteReducer,
  taskReducer,
  todoReducer,
  leaveReducer,
  commonReducer,
  holidayReducer,
  templateReducer,
  departmentReducer,
  notificationReducer,
  attendanceReducer,
  automationRulesReducer,
  chatReducer,
  serviceReducer,
  wapMonkeyReducer
};

export default rootReducer;
