export const StoreAction = {
  GET_USER: "GET_USER",
  GET_NOTE: "GET_NOTE",
  GET_UNIT: "GET_UNIT",
  GET_USER: "GET_USER",
  GET_LEAD_VIEW: "GET_LEAD_VIEW",
  GET_TASK_DATA: "GET_TASK_DATA",
  GET_TASK_VIEW: "GET_TASK_VIEW",
  SET_LEAD_VIEW: "SET_LEAD_VIEW",
  GET_TODO_LIST: "GET_TODO_LIST",
  GET_LEAD_LABEL: "GET_LEAD_LABEL",
  SET_IS_LOADING: "SET_IS_LOADING",
  GET_DEPARTMENT: "GET_DEPARTMENT",
  GET_TASK_LABEL: "GET_TASK_LABEL",
  SET_WEB_SOCKET: "SET_WEB_SOCKET",
  SET_GROUP_MOOD: "SET_GROUP_MOOD",
  GET_LEAD_STATUS: "GET_LEAD_STATUS",
  GET_TASK_STATUS: "GET_TASK_STATUS",
  SET_LEAD_SERACH: "SET_LEAD_SERACH",
  GET_LEAD_SOURCE: "GET_LEAD_SOURCE",
  GET_TODAY_LEADS: "GET_TODAY_LEADS",
  SET_ACTIVE_CHAT: "SET_ACTIVE_CHAT",
  SET_GROUP_MODAL: "SET_GROUP_MODAL",
  GET_HISTORY_DATA: "GET_HISTORY_DATA",
  GET_SMS_TEMPLATE: "GET_SMS_TEMPLATE",
  GET_TODAY_EVENTS: "GET_TODAY_EVENTS",
  GET_LEAVE_REASON:"GET_LEAVE_REASON",
  GET_TASK_HISTORY: "GET_TASK_HISTORY",
  SET_GROUP_MEMBER: "SET_GROUP_MEMBER",
  GET_ALL_TASK_DATA: "GET_ALL_TASK_DATA",
  GET_ALLSTAFF_DATA: "GET_ALLSTAFF_DATA",
  GET_FOLLOWUP_DATA: "GET_FOLLOWUP_DATA",
  GET_ALL_LEAD_DATA: "GET_ALL_LEAD_DATA",
  GET_ALL_TODO_LIST: "GET_ALL_TODO_LIST",
  GET_ALL_CHAT_LIST: "GET_ALL_CHAT_LIST",
  SET_SELECTED_CHAT: "SET_SELECTED_CHAT",
  SET_SCROLL_ADJECT: "SET_SCROLL_ADJECT",
  GET_SERVICE_STATUS: "GET_SERVICE_STATUS",
  SET_SCROLL_LOADING: "SET_SCROLL_LOADING",
  GET_ALL_LEAVE_DATA: "GET_ALL_LEAVE_DATA",
  GET_CALENDER_DATES: "GET_CALENDER_DATES",
  SET_IS_SEND_MESSAGE: "SET_IS_SEND_MESSAGE",
  SET_IS_TASK_LOADING: "SET_IS_TASK_LOADING",
  GET_TODAY_TASK_DATA: "GET_TODAY_TASK_DATA",
  GET_USER_DEPARTMENT: "GET_USER_DEPARTMENT",
  SET_IS_LEAD_LOADING: "SET_IS_LEAD_LOADING",
  GET_ALL_KANBAN_LEAD: "GET_ALL_KANBAN_LEAD",
  GET_CALENDER_EVENTS: "GET_CALENDER_EVENTS",
  GET_EMAIL_TEMPLATES: "GET_EMAIL_TEMPLATES",
  GET_WEBSETTING_DATA: "GET_WEBSETTING_DATA",
  GET_DEPTSETTING_DATA: "GET_DEPTSETTING_DATA",
  SET_CUSTOM_TODO_VIEW: "SET_CUSTOM_TODO_VIEW",
  SET_IS_NOTES_LOADING: "SET_IS_NOTES_LOADING",
  READ_ALL_NOTIFICATION: "READ_ALL_NOTIFICATION",
  GET_WHATSAPP_TEMPLATE: "GET_WHATSAPP_TEMPLATE",
  GET_LEAD_HISTORY_DATA: "GET_LEAD_HISTORY_DATA",
  GET_ALL_HOLIDAYS_LIST: "GET_ALL_HOLIDAYS_LIST",
  GET_MEETING_TEMPLATES: "GET_MEETING_TEMPLATES",
  GET_PENDING_TODO_LIST: "GET_PENDING_TODO_LIST",
  GET_ALL_TOMORROW_TASK: "GET_ALL_TOMORROW_TASK",
  IS_GLOBAL_SEARCH_OPEN: "IS_GLOBAL_SEARCH_OPEN",
  DAILY_STAFF_ATTENDANCE: "DAILY_STAFF_ATTENDANCE",
  GET_REMINDER_TEMPLATES: "GET_REMINDER_TEMPLATES",
  SET_WAP_MONKEY_USER_ID: "SET_WAP_MONKEY_USER_ID",
  TASKSTATUS_BTN_LOADING: "TASKSTATUS_BTN_LOADING",
  GET_ALL_TREE_DEPARTMENT: "GET_ALL_TREE_DEPARTMENT",
  GET_ALL_KANBAN_TASK_IDS: "GET_ALL_KANBAN_TASK_IDS",
  GET_RECURSIVE_TODO_LIST: "GET_RECURSIVE_TODO_LIST",
  GET_DASHBOARD_TODO_LIST: "GET_DASHBOARD_TODO_LIST",
  GET_SMS_AUTOMATIONRULES: "GET_SMS_AUTOMATIONRULES",
  SET_SERVICE_DATA: "SET_SERVICE_DATA",
  GET_REMINDER_MEETING_DATA: "GET_REMINDER_MEETING_DATA",
  SET_WAP_MONKEY_WEB_SOCKET: "SET_WAP_MONKEY_WEB_SOCKET",
  GET_ALL_NOTIFICATION_DATA: "GET_ALL_NOTIFICATION_DATA",
  GET_EMAIL_AUTOMATIONRULES: "GET_EMAIL_AUTOMATIONRULES",
  SET_WAP_MONKEY_DEVICE_DATA: "SET_WAP_MONKEY_DEVICE_DATA",
  SET_WAP_MONKEY_DEVICE_TYPE: "SET_WAP_MONKEY_DEVICE_TYPE",
  SET_IS_TODAY_TASKS_LOADING: "SET_IS_TODAY_TASKS_LOADING",
  GET_ALL_KANBAN_TASK_SEARCH: "GET_ALL_KANBAN_TASK_SEARCH",
  GET_TOTAL_ALL_TODO_COUNTER: "GET_TOTAL_ALL_TODO_COUNTER",
  GET_ALL_KANBAN_LEAD_SEARCH: "GET_ALL_KANBAN_LEAD_SEARCH",
  SET_MISS_FOLLOWUP_LEAD_DATA:"SET_MISS_FOLLOWUP_LEAD_DATA",
  SET_IS_INSERT_BUTTON_LOADING: "SET_IS_INSERT_BUTTON_LOADING",
  SET_IS_LEAD_FOLLOWUP_LOADING: "SET_IS_LEAD_FOLLOWUP_LOADING",
  SET_KANBAN_LEAD_DATA_LOADING: "SET_KANBAN_LEAD_DATA_LOADING",
  GET_WHATSAPP_AUTOMATIONRULES: "GET_WHATSAPP_AUTOMATIONRULES",
  IS_GLOBAL_SEARCH_SUGGESTION_OPEN: "IS_GLOBAL_SEARCH_SUGGESTION_OPEN",
  GET_TOTAL_RECURSIVE_TODO_COUNTER: "GET_TOTAL_RECURSIVE_TODO_COUNTER",
  SET_SERVICE_TABLE_DATA_UPDATED: 'SET_SERVICE_TABLE_DATA_UPDATED',
  SET_ALL_SERVICE_LIST: 'SET_ALL_SERVICE_LIST',
  SET_SERVICE_KANBAN_LOADING: "SET_SERVICE_KANBAN_LOADING",
  SET_KANBAN_SERVICE_DATA: "SET_KANBAN_SERVICE_DATA",
  SET_SERVICE_VIEW: "SET_SERVICE_VIEW",
  SET_SERVICE_HISTORY_LIST: "SET_SERVICE_HISTORY_LIST",
  SET_WAP_MONKEY_RECONNECT_DEVICE_DATA: "SET_WAP_MONKEY_RECONNECT_DEVICE_DATA",
};
