import { StoreAction } from "../../@core/constants/action";

const initialState = {
    serviceView: 0, // 0 - Kanban, 1 - Table
    serviceData: {},
    kanbanService: [],
    allServiceList: {},
    serviceHistoryList: [],
    serviceKanbanLoading: false,
    serviceTableDataUpdated: false, // false - not updated, true - updated
  };

  
  export default function (state = initialState, action) {
    switch (action.type) {
      case StoreAction.SET_SERVICE_DATA:
        return { ...state, serviceData: action.payload };
      case StoreAction.SET_SERVICE_VIEW:
        return { ...state, serviceView: action?.payload };
      case StoreAction.SET_KANBAN_SERVICE_DATA:
        return { ...state, kanbanService: action.payload };
      case StoreAction.SET_ALL_SERVICE_LIST:
        return { ...state, allServiceList: action.payload };
      case StoreAction.SET_SERVICE_HISTORY_LIST:
        return { ...state, serviceHistoryList: action.payload };
      case StoreAction.SET_SERVICE_KANBAN_LOADING:
        return { ...state, serviceKanbanLoading: action.payload };
      case StoreAction.SET_SERVICE_TABLE_DATA_UPDATED:
        return { ...state, serviceTableDataUpdated: action.payload };
      default:
        return state;
    }
  }
